import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-insta-feed',
  templateUrl: './insta-feed.component.html',
  styleUrls: ['./insta-feed.component.scss']
})
export class InstaFeedComponent implements OnInit {
  photos: Array<Array<string>> = [
    [1, 2].map(ord => `assets/images/insta/insta${ord}.jpg`),
    [3, 4].map(ord => `assets/images/insta/insta${ord}.jpg`),
    [5, 6].map(ord => `assets/images/insta/insta${ord}.jpg`),
    [7, 8].map(ord => `assets/images/insta/insta${ord}.jpg`)
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  openingHours = '';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('assets/opening-hours.txt', {responseType: 'text'}).subscribe(hours => this.openingHours = hours);
  }

}

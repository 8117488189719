<hr class="divider">

<a href="https://www.instagram.com/pastamiasta" target="_blank" rel="noopener nofolow">
  <div class="insta-name text-center">@pastamiasta</div>
</a>

<div class="gallery">
  <div class="gallery__column" *ngFor="let column of photos">
    <a href="https://www.instagram.com/pastamiasta/" target="_blank" class="gallery__link" *ngFor="let photo of column">
      <figure class="gallery__thumb">
        <img src="{{photo}}" alt="Portrait by Jessica Felicio" class="gallery__image">
      </figure>
    </a>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import 'foundation-sites';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-orbit',
  templateUrl: './orbit.component.html',
  styleUrls: ['./orbit.component.scss']
})
export class OrbitComponent implements OnInit, OnDestroy {
  orbits = [
    {
      image: '../../assets/images/banner_15911376430.jpg',
      caption: ''
    },
    {
      image: '../../assets/images/banner_15911376431.jpg',
      caption: ''
    }
  ];
  activeOrbit = 0;
  subscription: Subscription = new Subscription();
  #reset = new Subject<void>();
  #timer$: Observable<any>;

  constructor(private http: HttpClient) {
    this.#timer$ = this.#reset.pipe(
      startWith(0),
      switchMap(() => timer(10000, 10000))
    );
  }

  ngOnInit(): void {
    this.orbits.map((orbit, i) => {
      this.http.get(`assets/orbit-text-${i}.txt`, {responseType: 'text'}).subscribe(text => orbit.caption = text);
    });
    this.subscription.add(this.#timer$.subscribe(() => this.activateNextOrbit()));
  }

  activateOrbit(i: number): void {
    this.#reset.next();
    this.activeOrbit = i;
  }

  activateNextOrbit(): void {
    this.activeOrbit < this.orbits.length - 1 ? this.activeOrbit++ : this.activeOrbit = 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {
  isOpen = false;
  openingHours = '';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get('assets/opening-hours.txt', {responseType: 'text'}).subscribe(hours => this.openingHours = hours);
  }

  toggleNav(): void {
    this.isOpen = !this.isOpen;
  }

}

<div class="top-bar">
  <div class="top-bar-left">
    <div class="opening-hours text-center" [innerHTML]="openingHours">
    </div>
    <div class="adress text-center">
      Świętojańska 46, Gdynia<br>+ 48 786 121 100
    </div>
  </div>
  <div class="logo-container text-center">
    <img src="assets/images/logo_biale.svg" alt="logo">
  </div>
  <div class="top-bar-right">

    <a class="top-bar-right-link" href="assets/menuPOL.pdf">
      <div class="menu-regular text-center">
        <div class="menu-title">
          Menu
        </div>
        <div class="menu-text">
          Restauracja
        </div>
      </div>
    </a>

    <a class="top-bar-right-link" href="https://pasta-miasta.upmenusite.com" target="_blank" rel="noopener noreferrer">
      <div class="menu-regular text-center">
        <div class="menu-title">
          Zamów
        </div>
        <div class="menu-text">
          online
        </div>
      </div>
    </a>

    <a class="top-bar-right-link"
       href="https://www.google.com/maps/reserve/v/dine/c/Az783U4CH4U"
       target="_blank" rel="noopener noreferrer">
      <div class="menu-regular text-center">
        <div class="menu-title">
          Rezerwuj
        </div>
        <div class="menu-text">
          online
        </div>
      </div>
    </a>

    <a class="top-bar-right-link"
       href="https://form.jotform.com/240493128476360"
       target="_blank" rel="noopener noreferrer">
      <div class="menu-regular text-center">
        <div class="menu-title">
          Oferta
        </div>
        <div class="menu-text">
          Wielkanoc
        </div>
      </div>
    </a>
  </div>
</div>

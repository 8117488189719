<div class="title-bar">
  <div class="title-bar-left">
    <button class="menu-icon" type="button" (click)="toggleNav()"></button>
  </div>
  <div class="logo-container text-center">
    <img src="../../assets/images/logo_biale.svg" alt="logo">
  </div>
  <div class="title-bar-right">
    <a href="tel:+48 786 121 100">
      <div class="reservations-phone">
        <img src="../../assets/images/phone.svg" alt="Phone">
      </div>
    </a>
  </div>
</div>

<div class="off-canvas-wrapper">
  <div class="off-canvas position-left" [ngClass]="{'is-open': isOpen}">
    <button class="close-button" aria-label="Close menu" type="button" (click)="toggleNav()">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="address-container">
      <div class="opening-hours text-center" [innerHTML]="openingHours">
        <div class="address text-center">
          Świętojańska 46, Gdynia<br>+ 48 786 121 100
        </div>
      </div>

      <a href="assets/menuPOL.pdf">
        <div class="menu-regular text-center">
          <div class="menu-title">
            Menu
          </div>
          <div class="menu-text">
            Restauracja
          </div>
        </div>
      </a>

      <a href="https://pasta-miasta.upmenusite.com" target="_blank" rel="noopener noreferrer">
        <div class="menu-regular text-center">
          <div class="menu-title">
            Zamów
          </div>
          <div class="menu-text">
            online
          </div>
        </div>
      </a>

      <a href="https://www.google.com/maps/reserve/v/dine/c/Az783U4CH4U" target="_blank" rel="noopener noreferrer">
        <div class="menu-regular text-center">
          <div class="menu-title">
            Rezerwuj
          </div>
          <div class="menu-text">
            online
          </div>
        </div>
      </a>

      <a href="https://form.jotform.com/240493128476360" target="_blank" rel="noopener noreferrer">
        <div class="menu-regular text-center">
          <div class="menu-title">
            Oferta
          </div>
          <div class="menu-text">
            Wielkanoc
          </div>
        </div>
      </a>

    </div>
  </div>
</div>

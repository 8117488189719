<div class="orbit" role="region" aria-label="Restaurant interior">
  <div class="orbit-wrapper">
    <div class="dimmer"></div>
    <ul class="orbit-container" style="min-height: 100vh">
      <li class="orbit-slide" *ngFor="let orbit of orbits; let i = index" [ngClass]="{'is-active': i === activeOrbit}">
        <img class="orbit-image" [src]="orbit.image" alt="Restaurant">
      </li>
    </ul>
  </div>
  <nav class="orbit-bullets">
    <button *ngFor="let _ of orbits; let i = index" (click)="activateOrbit(i)"
            [ngClass]="{'is-active': i === activeOrbit}">
    </button>
  </nav>
  <span class="orbit-caption" *ngFor="let orbit of orbits; let i = index" [ngClass]="{'is-active': i === activeOrbit}">
    <p [innerHTML]="orbit.caption">
    </p>
  </span>
</div>

<div class="grid-x footer-container">
  <div class="cell small-4 social-icons-container">
    <a href="https://www.facebook.com/pastamiasta" target="_blank" rel="noopener nofolow">
      <i class="fi-social-facebook social-icon"></i>
    </a>
    <a href="https://www.instagram.com/pastamiasta" target="_blank" rel="noopener nofolow">
      <i class="fi-social-instagram social-icon"></i>
    </a>
  </div>
  <div class="cell small-4 text-center copyright">
    <span class="copyright-text">Copyright © 2021 Pasta Miasta Deli.</span>
  </div>
</div>
